import { hasCapabilitySupport } from "../app/Capabilities";
import { getAppData } from "../app/data";
import { share } from "../pages/monitoring/TerminalMonitoringView";
import Api from "../session/Api";
import UIUtil from "../util/UIUtil";

/**
 * @typedef {(
 *  'ReceiptVoucher' |
 *  'CreditNote' |
 *  'TaxInvoice' |
 *  'Quotation' |
 *  'ProformaInvoice' | 
 *  'SalesOrder' | 
 *  'PurchaseOrder' |
 *  'StockRequest'
 * )} TemplateName
 */


/**

 * @param {TemplateName} name 
 * @param {number} id 
 */
export async function openPdf(name, id) {
    if (!hasCapabilitySupport("templateEngine")) {
        UIUtil.showInfo("No templates available");
        return;
    }

    const stopLoading = await UIUtil.pageLoader();
    try {
        const appData = await getAppData();
        // console.log(appData);
        const def = appData.templateOptions[name];
        if (!def?.templates?.length) {
            UIUtil.showInfo("No templates available");
            return;
        }

        let option;

        if (def.templates.length > 1) {
            option = await UIUtil.listPrompt(def.title, def.templates
                .map(templ => ({ id: templ.path, value: templ.name })))
        } else {
            const firstTempl = def.templates[0];
            option = { id: firstTempl.path, value: firstTempl.name };
        }

        if (!option) {
            return;
        }

        window.open(Api.getTemplateEnginePath(name, option.id, id))

    } finally {
        stopLoading();
    }
}


/**

 * @param {TemplateName} name 
 * @param {number} id 
 */
export async function sharePdf(name, id) {
    const stopLoading = await UIUtil.pageLoader();
    try {
        const appData = await getAppData();
        const def = appData.templateOptions[name];
        if (!def?.templates?.length) {
            UIUtil.showInfo("No templates available");
            return;
        }

        let option;

        if (def.templates.length > 1) {
            option = await UIUtil.listPrompt(def.title, def.templates
                .map(templ => ({ id: templ.path, value: templ.name })))
        } else {
            const firstTempl = def.templates[0];
            option = { id: firstTempl.path, value: firstTempl.name };
        }

        if (!option) {
            return;
        }

        share(Api.getPublicTemplateEnginePath(name, option.id, id), () => {
            UIUtil.showInfo("Browser not supported")
        })

    } finally {
        stopLoading();
    }
}
