import React, { useEffect, useState } from 'react'
import { ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput, ClickableTile, ButtonSet, ContentSwitcher, Switch, ComboBox, Tooltip, TooltipIcon, Checkbox, TextArea } from 'carbon-components-react';
import { Purchase16, Star16, CloseFilled16, CheckmarkFilled16, WarningFilled24, Money32, Error16, ErrorFilled16, Search16, Subtract16, Add16, RowDelete32, ArrowRight16, WarningFilled32, Cafe16, Cafe32, Time16, Hourglass16, DeliveryParcel16 } from '@carbon/icons-react'
import Util from '../../util/Util';
import { PosInfoView, PosInfoViewBtns } from './view/PosInfoView';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import { OBJECT_TYPE_CUSTOMER } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import Button from '../../components/Button';
import TransactionEditor from '../transaction/TransactionEditor';
import { PAYMENT_METHOD_CASH, TRANSACTION_DIRECTION_TYPE_INWARD } from '../../constants/Constants';
import { getTerminal } from '../../session/SessionManager';
import ContinuousQuantityDialog from './dialogs/ContinuousQuantityDialog';
import { useRef } from 'react';
import { SetCustomPriceDialog } from './dialogs/set-custom-price-dialog';
import { REST_ORDER_TYPE } from '../../domain/rest-sales-order';
import { TableDialog } from './restaurant/dialog/table-dialog';
import { hasCapabilitySupport } from '../../app/Capabilities';
import { FinalReceiptDialog } from './dialogs/final-receipt-dialog';
import { CreateDeliveryOrderDialog } from './restaurant/dialog/create-delivery-order-dialog';
import { SessionInfoDialog } from './dialogs/session-info-dialog';
import { isAbsharShop, isFoodBazaar } from '../../app/app-util';
import { TIP_ENABLED } from '../../app/Config';


const EndingPosDialog = ({ state: posState }) => {
    const [printSalesSummary, setPrintSalesSummary] = useState(false)
    useEffect(() => {
        if (posState.isShowEndPosDialog()) {
            setPrintSalesSummary(false)
        }
    }, [posState.isShowEndPosDialog()])

    const logoutOnEnd = posState.getSession()?.logoutOnEndSession === true;
    return (
        <ComposedModal key="stop-session-dialog" size="sm" open={posState.isShowEndPosDialog()} onClose={() => posState.setShowEndPosDialog(false)}>
            <ModalHeader label="POS" title="Stop Session" />
            <ModalBody>
                {posState.getSession() && <>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Suggested cash closing amount</p>
                    {(posState.getSession().suggestedEndingAmount && posState.getSession().suggestedEndingAmount > 0) ? (
                        <h4 style={{ color: 'green' }}>AED {posState.getSession().suggestedEndingAmount}</h4>
                    ) : (
                        <h4>-</h4>
                    )}
                    <div style={{ height: '1rem' }} />

                    {/* <TooltipIcon tooltipText={'Calculated using: '} renderIcon={Information16} /> */}
                </>}


                <NumberInput
                    invalidText="Invalid number"
                    data-modal-primary-focus
                    value={posState.getEndPosAmount()}
                    onChange={(e, { value }) => posState.setEndPosAmount(value)}
                    hideSteppers
                    label="Cash closing amount"
                />
                <div style={{ height: '1rem' }} />

                <div onClick={() => setPrintSalesSummary(p => !p)}>
                    <Checkbox labelText="Print sales summary report" checked={printSalesSummary} />
                </div>

                {logoutOnEnd && <div style={{
                    marginTop: '1rem', borderRadius: 7, border: '1px solid #99000040', background: "#ffff001f", padding: '1rem', color: 'darkorange',
                    display: 'flex', alignItems: 'center', gap: '0.5rem'
                }}>
                    <WarningFilled24 />
                    <p>You will be logged out after stopping session!</p>
                </div>}
            </ModalBody>
            <ModalFooter
            // onRequestSubmit={() => posState.stopSession()}
            // primaryButtonText="Stop" secondaryButtonText="Cancel"
            >
                <Button kind="secondary" onClick={() => posState.setShowEndPosDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (printSalesSummary) {
                            posState.printSalesSummary(() => { }, { skipSuccess: true })
                        }
                        posState.stopSession()
                    }}>
                    Stop
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}


const CheckoutDialog = ({ state }) => {
    const [editorKey, setEditorKey] = useState(Util.newTempId());
    const [paymentMethods, setPaymentMethods] = useState([]);

    const [note, setNote] = useState("");
    const [memo, setMemo] = useState("");

    const [buildingName, setBuildingName] = useState("");
    const [address, setAddress] = useState("");

    const amount = state.isShowCheckoutDialog() ? state.getRawTotal() : 0;
    const isAmountZero = amount != null && amount.toFixed(2) == "0.00";

    const forDineIn = state.isShowDineInCheckoutDialog();
    const forTakeOut = state.isShowTakeOutCheckoutDialog();
    const forDelivery = state.isShowDeliveryCheckoutDialog();

    const customerId = Util.isNumberExist(state.getSession()?.customerId) ? state.getSession().customerId : undefined;

    const amountPaid = () => {
        let totalPaid = 0;
        let totalCashAmount = 0;
        let totalNonCashAmount = 0;

        for (const paymentMethod of paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                const amount = parseFloat(paymentMethod.amount);

                totalPaid += amount;
                if (paymentMethod.methodType == PAYMENT_METHOD_CASH) {
                    totalCashAmount += amount;
                } else {
                    totalNonCashAmount += amount;
                }
            }
        }

        return (totalPaid == amount) || (totalPaid > amount && totalNonCashAmount <= amount);
    }

    const getCashbackAmount = () => {
        if (TIP_ENABLED) {
            return 0;
        }

        let totalPaid = 0;
        let totalCashAmount = 0;
        let totalNonCashAmount = 0;

        for (const paymentMethod of paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                const amount = parseFloat(paymentMethod.amount);

                totalPaid += amount;
                if (paymentMethod.methodType == PAYMENT_METHOD_CASH) {
                    totalCashAmount += amount;
                } else {
                    totalNonCashAmount += amount;
                }
            }
        }

        return totalCashAmount - (amount - totalNonCashAmount).toFixed(2)
    }

    const getTotalCashAmount = () => {
        let totalPaid = 0;
        let totalCashAmount = 0;
        let totalNonCashAmount = 0;

        for (const paymentMethod of paymentMethods) {
            if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                const amount = parseFloat(paymentMethod.amount);

                totalPaid += amount;
                if (paymentMethod.methodType == PAYMENT_METHOD_CASH) {
                    totalCashAmount += amount;
                } else {
                    totalNonCashAmount += amount;
                }
            }
        }

        return totalCashAmount;
    }

    useEffect(() => {
        setEditorKey(Util.newTempId())
        setPaymentMethods([])
        setMemo("");
        setNote("")
        setBuildingName("");
        setAddress("")
    }, [state.isShowCheckoutDialog()])


    if (forDineIn) {
        return (
            <ComposedModal open={state.isShowCheckoutDialog()} onClose={() => state.setShowCheckoutDialog(false)}>
                <ModalHeader label="New Order" title="Dine-in" />
                <ModalBody style={{ paddingRight: '1rem', marginBottom: '0rem' }}>
                    <TextArea rows={3} labelText="Additional Note" value={note} onChange={e => setNote(e.target.value)} />
                    <div style={{ marginBottom: '1rem' }} />

                </ModalBody>
                <ModalFooter
                >

                    <Button kind="secondary" onClick={() => state.setShowCheckoutDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            state.createSalesOrder(REST_ORDER_TYPE.DINE_IN, {
                                preferences: Util.isStringExists(note) ? JSON.stringify([{ id: 'note', label: "Note", value: note }]) : undefined
                            });
                        }}>
                        {state.isCheckoutLoading() ? "Loading..." : "Confirm"}
                    </Button>

                </ModalFooter>
            </ComposedModal>
        )
    }

    let allowNoPayment = isAbsharShop();

    if (!customerId) {
        allowNoPayment = false;
    }

    const paymentNotSufficient = !allowNoPayment && (!isAmountZero && (paymentMethods.length == 0 || !amountPaid()));
    const paymentActuallyNotSufficient = (!isAmountZero && (paymentMethods.length == 0 || !amountPaid()));;

    return (
        <ComposedModal open={state.isShowCheckoutDialog()} onClose={() => state.setShowCheckoutDialog(false)} size="lg">
            <ModalHeader label="Checkout" title="Add payment methods" />
            <ModalBody style={{ paddingRight: '1rem', marginBottom: '0rem' }}>
                {forTakeOut && (<>
                    <TextInput labelText="Memo" value={memo} onChange={e => setMemo(e.target.value)} />
                    <div style={{ height: '1rem' }} />
                    <TextArea rows={3} labelText="Additional Note" value={note} onChange={e => setNote(e.target.value)} />
                    <div style={{ marginBottom: '1rem' }} />
                </>)}

                {forDelivery && (<>
                    <TextInput labelText="Building" value={buildingName} onChange={e => setBuildingName(e.target.value)} />
                    <div style={{ marginBottom: '1rem' }} />
                    <TextArea rows={3} labelText="Address" value={address} onChange={e => setAddress(e.target.value)} />
                    <div style={{ marginBottom: '1rem' }} />
                </>)}

                {!isAmountZero ?
                    (<TransactionEditor key={editorKey}
                        onUpdate={paymentMethods => setPaymentMethods(paymentMethods)}
                        currency={"AED"}
                        posMode
                        incoming
                        hideDirection
                        showPosDiscountAmount
                        customerId={customerId}
                        totalAmount={amount} />) : (
                        <p style={{ marginBottom: '1rem' }}>No amount</p>
                    )}

            </ModalBody>
            <ModalFooter
            >

                <Button kind="secondary" onClick={() => state.setShowCheckoutDialog(false)}>
                    Cancel
                </Button>
                <Button
                    //disabled={(!isAmountZero && (paymentMethods.length == 0 || !amountPaid())) || state.isCheckoutLoading()}
                    disabled={hasCapabilitySupport("restaurant") ? false : (paymentNotSufficient || state.isCheckoutLoading())}
                    onClick={() => {
                        const cashbackAmount = getCashbackAmount();
                        const totalCashAmount = getTotalCashAmount();
                        const onSuccess = () => {
                            if (Util.isNumberExist(cashbackAmount)) {
                                UIUtil.openDialog(({ onClose }) => (
                                    <div style={{ background: 'rgb(244, 244, 244)', width: 392, overflow: 'hidden' }}>
                                        {/* <ModalHeader label="Cash" title="Are you sure?" /> */}
                                        <p style={{ color: 'green', textAlign: 'center', width: '100%', marginTop: '1rem' }}>Checkout successful</p>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '3rem', marginBottom: '3rem' }}>
                                            <Money32 style={{ fill: 'green', width: 128, height: 128 }} />
                                            <h2 style={{ color: 'green' }}>Cash Payment</h2>

                                            <p style={{ fontSize: 14, marginTop: '1.5rem' }}>Total Cash Paid</p>
                                            <h6>AED {totalCashAmount.toFixed(2)}</h6>

                                            <p style={{ marginTop: '1rem', color: 'red' }}>Cash Back</p>
                                            <h5 style={{ color: 'red', fontSize: 22 }}>AED {cashbackAmount.toFixed(2)}</h5>
                                        </div>
                                        {/* <div style={{height: 15}} /> */}
                                        <ButtonSet style={{ justifyContent: 'flex-end' }}>
                                            {/* <Button kind="secondary" onClick={onClose} renderIcon={CloseFilled16}>
                                            Cancel
                                        </Button> */}
                                            <Button onClick={() => {
                                                // onConfirm();
                                                onClose()
                                            }} renderIcon={CheckmarkFilled16}>
                                                OK
                                            </Button>
                                        </ButtonSet>
                                    </div>
                                ))
                            }
                        }
                        if (forTakeOut) {
                            state.createSalesOrder(REST_ORDER_TYPE.TAKE_OUT, {
                                memo, paymentBeforeInvoice: paymentMethods,
                                preferences: Util.isStringExists(note) ? JSON.stringify([{ id: 'note', label: "Note", value: note }]) : undefined
                            });
                        } else if (forDelivery) {
                            state.createSalesOrder(REST_ORDER_TYPE.DELIVERY, {
                                shippingAddress: {
                                    addressLineOne: address,
                                    buildingName
                                },
                                paymentBeforeInvoice: paymentMethods
                            })
                        } else {
                            if (paymentActuallyNotSufficient) {
                                UIUtil.confirmPrompt({
                                    message: "No payment method selected. Are you sure?",
                                    positiveAction: "Create Unpaid Sale"
                                }).then(confirm => {
                                    if (confirm) {
                                        state.onCheckoutBtn(paymentMethods, cashbackAmount, onSuccess)
                                    }
                                })
                            } else {
                                state.onCheckoutBtn(paymentMethods, cashbackAmount, onSuccess)
                            }
                        }
                    }}>
                    {state.isCheckoutLoading() ? "Loading..." : "Confirm"}
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}


const HighlightItemDialog = ({ state }) => {
    const onSearchBtn = (forcedValue) => {
        const value = forcedValue !== undefined ? forcedValue : state.getHighlightProductBarcodeValue()
        state.setShowHighlightProductDialog(false, value);
    }

    return (
        <ComposedModal open={state.isShowHighlightProductDialog()} onClose={() => state.setShowHighlightProductDialog(false, "")} size="xs">
            <ModalHeader label="Scan Barcode" title="Highlight by Barcode" />
            <ModalBody>
                <TextInput
                    style={{ width: '100%' }}
                    data-modal-primary-focus
                    value={state.getHighlightProductBarcodeValue()}
                    onPaste={e => onSearchBtn((e.clipboardData || window.clipboardData).getData('text'))}
                    onChange={e => state.setHighlightProductBarcodeValue(e.target.value)}
                    labelText="Barcode"
                    placeholder="Scan barcode"
                />
            </ModalBody>
            <ModalFooter >

                <Button kind="secondary" onClick={() => state.setShowHighlightProductDialog(false, "")}>
                    Cancel
                </Button>
                <Button
                    disabled={!Util.isStringExists(state.getHighlightProductBarcodeValue())}
                    onClick={() => onSearchBtn()}>
                    Search
                </Button>

            </ModalFooter>
        </ComposedModal>
    )
}


const HeldCartItem = ({ posState, cart, index, onDeleted }) => {
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const onSetCart = () => {
        setLoading(true);
        posState.api.setCart(cart.id, response => {
            setLoading(false);
            if (response.status === true) {
                posState.onUpdateSession(response.payload)
                posState.setShowHeldCartsDialog(false)
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            setDeleting(true);
            posState.api.deleteCart(cart.id, response => {
                setDeleting(false)
                if (response.status === true) {
                    onDeleted();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }

    const customerName = Util.isStringExists(cart.customerName) ? cart.customerName : "Guest";
    const totalItems = cart.totalItems + " items";
    const totalAmount = cart.totals.currency + " " + cart.totals.total.toFixed(2);

    return (
        <div style={{ marginBottom: '0.5rem', display: 'flex' }}>
            <ClickableTile light disabled={loading || deleting} handleClick={onSetCart} style={{ flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <h4 style={{ fontSize: 14 }}>Cart #{index}</h4>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>
                            {cart.externallyOriginated && <><span style={{ color: 'red', fontWeight: 'bold' }}>Sales app originated</span> -</>}
                            {Util.getDate(cart.date)}
                        </p>
                    </div>

                    <p style={{ fontSize: 12 }}>{customerName}</p>
                    <div style={{ width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1 }} />
                    <p style={{ fontSize: 12 }}>{totalItems}</p>
                    <div style={{ width: 4, height: 4, marginLeft: 5, marginRight: 5, borderRadius: '50%', background: 'black', opacity: 1 }} />
                    <p style={{ fontSize: 12, color: 'green' }}>{totalAmount}</p>

                    <div style={{ width: 10 }} />
                    {loading ? <InlineLoading style={{ width: 16 }} /> : <ArrowRight16 />}
                </div>
            </ClickableTile>
            <Button loading={deleting} disabled={loading} onClick={onDeleteBtn} kind="danger--tertiary" className="bx--tooltip--hidden" renderIcon={RowDelete32} hasIconOnly data-tip="Delete Cart" />
        </div>
    )
}

export function PosFreeFormDialog({ posState, showFakeTextFieldAtFirst }) {
    const forCartItem = posState?.getState?.()?.freeFormDiscountCartItemOnly;

    const inputRef = useRef();
    const [showTextField, setShowTextField] = useState(!showFakeTextFieldAtFirst);
    const [showFakeTextField, setShowFakeTextField] = useState(showFakeTextFieldAtFirst);

    useEffect(() => {
        if (showTextField && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showTextField])

    useEffect(() => {
        if (posState.isShowFreeFormDiscountDialog() && showFakeTextFieldAtFirst) {
            setShowTextField(false);
            setShowFakeTextField(true)
        }
    }, [posState.isShowFreeFormDiscountDialog()])

    return (
        <ComposedModal key="apply-free-form-dialog" size="sm" open={posState.isShowFreeFormDiscountDialog()} onClose={() => posState.setShowFreeFormDiscountDialog(false)}>
            <ModalHeader label="POS" title={forCartItem ? "Apply Item Discount" : "Apply Free-Form Discount"} />
            <ModalBody>
                <ContentSwitcher style={{ marginBottom: '1rem' }}
                    selectedIndex={posState.getState().freeFormDiscountPercMode ? 1 : 0} onChange={({ index }) => {
                        posState.setState({ freeFormDiscountPercMode: index == 1 })
                    }}>
                    <Switch text="Amount" />
                    <Switch text="Percentage" />
                </ContentSwitcher>

                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0rem', flexWrap: 'wrap' }}>
                    <TextInput
                        ref={inputRef}

                        onKeyDown={e => {
                            if (e.key == "Tab") {
                                e.preventDefault();
                            }
                        }}

                        data-modal-primary-focus
                        labelText={posState.getState().freeFormDiscountPercMode ? "Percentage" : "Amount"}
                        value={posState.getFreeFormDiscountAmountValue()}
                        onChange={e => posState.setFreeFormDiscountAmountValue(e.target.value)}
                        style={{ display: !showTextField ? 'none' : undefined }}
                    />

                    <div className="input bx--text-input bx--form-item bx--text__input bx--text-input--light" onClick={() => {
                        setShowTextField(true)
                        setShowFakeTextField(false)
                    }} style={{
                        display: !showFakeTextField ? 'none' : 'flex',
                        justifyContent: 'center',
                        color: 'rgba(0,0,0,0.30)',
                        cursor: 'text',
                        flexBasis: '100%'
                    }}>{posState.getFreeFormDiscountAmountValue()}</div>

                    {(Util.isNumberExist(forCartItem?.discountAmount) || Util.isNumberExist(forCartItem?.discountPerc)) &&
                        <Button onClick={() => posState.setFreeFormDiscountAmountValue("0")} size="md" kind="danger" renderIcon={ErrorFilled16}>Clear</Button>}
                </div>
                {(Util.isNumberExist(forCartItem?.discountAmount) || Util.isNumberExist(forCartItem?.discountPerc)) && <p style={{ color: 'red', fontWeight: 'bold' }}>Set amount to 0 to clear discount.</p>}


                {!posState.monitoringSession && <>
                    <div style={{ height: '1rem' }} />
                    <TextInput
                        data-modal-primary-focus
                        labelText="Clearance Code"
                        type="password"
                        autoComplete="off"
                        helperText="Admin or supervisor clearance code"
                        value={posState.getFreeFormDiscountClearanceCodeValue()}
                        onChange={e => posState.setFreeFormDiscountClearanceCodeValue(e.target.value)}
                    />
                </>}


            </ModalBody>
            <ModalFooter
            >
                <Button kind="secondary" onClick={() => posState.setShowFreeFormDiscountDialog(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={!Util.isStringExists(posState.getFreeFormDiscountAmountValue()) ||
                        (
                            !posState.monitoringSession ?
                                !Util.isStringExists(posState.getFreeFormDiscountClearanceCodeValue())
                                : false
                        ) ||
                        posState.isLoadingAddingFreeFormDiscount() || !posState.isShowFreeFormDiscountDialog()}
                    onClick={() => posState.addFreeFormDiscount()}>
                    {posState.isLoadingAddingFreeFormDiscount() ? "Loading..." : "Apply"}
                </Button>
            </ModalFooter>
        </ComposedModal>
    )
}

export default ({ posState, tab }) => (<>
    <CheckoutDialog state={posState} />
    <HighlightItemDialog state={posState} />
    {tab === "pos" && hasCapabilitySupport("restaurant") && <TableDialog state={posState} />}


    {/* Held carts */}
    <ComposedModal key="held-carts-dialog" size="md" open={posState.isShowHeldCartsDialog()} onClose={() => posState.setShowHeldCartsDialog(false)}>
        <ModalHeader label="POS" title="Held Carts" />
        <ModalBody style={{ paddingRight: '1rem' }}>
            {posState.getHeldCarts().length == 0 ? (
                <p style={{ fontSize: 14, opacity: 0.65 }}>No carts are on hold</p>
            ) : posState.getHeldCarts().map((cart, index) => (
                <HeldCartItem key={"held-cart-item-" + cart.id} posState={posState} cart={cart} index={index} onDeleted={() => posState.removeCartFromHold(cart)} />
            ))}
        </ModalBody>
        <ModalFooter
        //secondaryButtonText="Close"
        >
            <Button kind="secondary" onClick={() => posState.setShowHeldCartsDialog(false)}>
                Close
            </Button>
        </ModalFooter>
    </ComposedModal>





    {/* Customer creator */}
    <ItemCreatorDialog
        hideThumbnail
        onFieldUpdate={(property, value) => posState.api.setUpdateValue(getTerminal().id, property, value, posState.updateId, () => { })}

        valueUpdateFullName={{ property: "fullName", value: posState.getState().valueNewCustomerUpdateFullName }}
        valueUpdateEmailAddress={{ property: "email", value: posState.getState().valueNewCustomerUpdateEmailAddress }}
        valueUpdatePhoneNumber={{ property: "phoneNumber", value: posState.getState().valueNewCustomerUpdatePhoneNumber }}

        filterProperties={["fullName", "email", "phoneNumber", "fazzaCardNo", "esaadCardNo"]}
        objectType={OBJECT_TYPE_CUSTOMER}
        open={posState.isShowCustomerCreatorDialog()} onClose={() => posState.setShowCustomerCreatorDialog(false)}
        onCreatedItem={createdItem => {
            posState.onCreatingCustomer();
            posState.api.setPosCustomer(createdItem.id, response => {
                posState.setCreatingCustomerLoading(false);
                if (response.status === true) {
                    posState.onUpdateSession(response.payload)
                    UIUtil.showSuccess();
                } else {
                    posState.api.openNewCustomerDialog(getTerminal().id, false, () => { });
                    UIUtil.showError(response.message);
                }
            })
            // posState.api.searchPosCustomer(createdItem.email, response => {
            //     posState.setCreatingCustomerLoading(false);
            //     if (response.status === true) {
            //         posState.onUpdateSession(response.payload)
            //         UIUtil.showSuccess();
            //     } else {
            //         posState.api.openNewCustomerDialog(getTerminal().id, false, () => {});
            //         UIUtil.showError(response.message);
            //     }
            // })
        }} />

    <ComposedModal open={posState.getState().showSearchCustomerDialog} onClose={() => posState.setState({ showSearchCustomerDialog: false }, () => posState.api.openCustomerSearchDialog(getTerminal().id, false, () => { }))}
        key={"customer-search"}
        size={"sm"}>
        <ModalHeader label="Searching" title="Customer" />
        <ModalBody >
            <div style={{ marginBottom: '1rem' }}>
                <TextInput
                    labelText="Email or number"
                    value={posState.getState().searchCustomerValue}
                    onChange={e => {
                        posState.setState({ searchCustomerValue: e.target.value })
                        posState.api.setUpdateValue(getTerminal().id, "searchCustomerValue", e.target.value, posState.updateId, () => { })
                    }}
                />
            </div>
        </ModalBody>
        <ModalFooter>
            <Button //disabled={posState.getState().loadingSearchCustomer} 
                onClick={() => posState.setState({ showSearchCustomerDialog: false }, () => posState.api.openCustomerSearchDialog(getTerminal().id, false, () => { }))} kind="secondary" style={{ minWidth: 200 }} renderIcon={Error16} size="lg">Cancel</Button>
            <Button loading={posState.getState().loadingSearchCustomer} onClick={() => {
                //REPEATED IN CUSTOMER INFO SEARCH BUTTON
                posState.setState({ loadingSearchCustomer: true })
                posState.api.searchPosCustomer(posState.getState().searchCustomerValue, response => {
                    if (response.status === true) {
                        posState.setState({ showSearchCustomerDialog: false, loadingSearchCustomer: false })
                        posState.onUpdateSession(response.payload)
                        UIUtil.showSuccess();
                    } else {
                        posState.setState({ loadingSearchCustomer: false })
                        UIUtil.showError(response.message);
                    }
                })
            }} style={{ minWidth: 200 }} renderIcon={Search16} size="lg" disabled={!Util.isStringExists(posState.getState().searchCustomerValue)}>Search</Button>
        </ModalFooter>
    </ComposedModal>


    {/* Break dialogs */}
    <ComposedModal key="resume-from-break-dialog" size="sm" open={posState.isShowResumeFromBreakDialog()} onClose={() => posState.closeResumeFromBreakDialog()}>
        <ModalHeader label="POS" title="Resume Session" />
        <ModalBody style={{ paddingRight: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3rem', marginTop: '1rem' }}>
                <WarningFilled32 />
                <h2 style={{ fontSize: 16, marginLeft: '1rem', }}>Security clearance required!</h2>
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '75%', height: 64, overflow: 'hidden' }}>
                    <TextInput.PasswordInput
                        value={posState.getResumePosClearanceCode()}
                        onChange={e => posState.setResumePosClearanceCode(e.target.value)}
                        style={{ width: '100%', }}

                        data-modal-primary-focus
                        labelText="Clearance Code"
                        placeholder="Input your clearance code to continue"
                    />
                </div>
            </div>
        </ModalBody>
        <ModalFooter
        >
            <Button kind="secondary" onClick={() => posState.closeResumeFromBreakDialog()}>
                Cancel
            </Button>
            <Button
                disabled={!Util.isStringExists(posState.getResumePosClearanceCode())}
                onClick={() => posState.setOnBreak(false)}>
                Resume
            </Button>
        </ModalFooter>
    </ComposedModal>


    {/* <ComposedModal key="pos-session-info-dialog" size="sm" open={posState.isShowPosInfoDialog()} onClose={() => posState.setShowPosInfoDialog(false)}>
        <ModalHeader label="Session" title="POS Management" />
        <ModalBody style={{ paddingRight: '1rem' }}>
            <PosInfoView posState={posState} onClose={() => posState.setShowPosInfoDialog(false)} />
        </ModalBody>
        <ModalFooter>
            <PosInfoViewBtns posState={posState} />
        </ModalFooter>
    </ComposedModal> */}
    <SessionInfoDialog posState={posState} />


    <ComposedModal key="go-on-break-dialog" size="xs" open={posState.isShowGoOnBreakConfirm()} onClose={() => posState.declineConfirmToGoOnBreak()}>
        <ModalHeader label="Confirm" title="Are you sure?" />
        <ModalBody>
            <p>Do you want to go on a break?</p>
        </ModalBody>
        <ModalFooter>
            <Button kind="secondary" onClick={() => posState.declineConfirmToGoOnBreak()}>
                Cancel
            </Button>
            <Button
                onClick={() => posState.setOnBreak(true)}>
                Yes
            </Button>
        </ModalFooter>
    </ComposedModal>






    {/* Ending pos */}
    <EndingPosDialog state={posState} />




    {/* Ending pos */}
    <ContinuousQuantityDialog
        key="set-item-qty-dialog-continuous"
        cartItem={posState.getShowQuantitySetItem()}
        open={posState.isShowQuantitySetDialog() && posState.getShowQuantitySetItem() && posState.getShowQuantitySetItem().continuousStockType}
        onSet={({ quantityValue, displayUom }) => {
            //SAME CODE BELOW FOR OTHER STOCK TYPE
            posState.updateContinuousCartItem(posState.getShowQuantitySetItem().itemId, quantityValue, displayUom, {
                recipeOptions: posState.getShowQuantitySetItem().recipeOptions,
                batchNo: posState.getShowQuantitySetItem().invBatchNo
            });
            posState.setShowQuantitySetDialog(undefined)
        }}
        onClose={() => posState.setShowQuantitySetDialog(undefined)}
    />

    <ComposedModal key="set-item-qty-dialog" size="xs" open={posState.isShowQuantitySetDialog() && posState.getShowQuantitySetItem() && !posState.getShowQuantitySetItem().continuousStockType} onClose={() => posState.setShowQuantitySetDialog(undefined)}>
        <ModalHeader label="Cart Item" title="Set Quantity" />
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>

            {posState.getShowQuantitySetItem() && posState.getShowQuantitySetItem().continuousStockType ? (<>
            </>) : (<>
                <Button disabled={posState.getQuantitySetValue() <= 0}
                    onClick={() => posState.setQuantitySetValue(parseFloat(posState.getQuantitySetValue()) - 1)}
                    kind="danger" hasIconOnly renderIcon={Subtract16} className="bx--tooltip--hidden" data-tip="Subtract" />
                <div>
                    <NumberInput
                        invalidText="Invalid number"
                        data-modal-primary-focus
                        size="xl"
                        value={posState.getQuantitySetValue()}
                        onChange={(e, { value }) => posState.setQuantitySetValue(value)}
                        hideSteppers
                        hideLabel
                    />
                </div>
                <Button hasIconOnly size="lg" data-tip="Add"
                    onClick={() => posState.setQuantitySetValue(parseFloat(posState.getQuantitySetValue()) + 1)}
                    renderIcon={Add16} className="bx--tooltip--hidden" />
            </>)}
        </ModalBody>
        <ModalFooter

        >
            <Button kind="secondary" onClick={() => posState.setShowQuantitySetDialog(undefined)}>
                Cancel
            </Button>
            <Button
                disabled={(posState.getShowQuantitySetItem() !== undefined && posState.getQuantitySetValue() == posState.getShowQuantitySetItem().quantityValue) || (isNaN(posState.getQuantitySetValue()) || posState.getQuantitySetValue() === "")}
                onClick={() => {
                    //SAME CODE ABOVE FOR OTHER STOCK TYPE
                    const diff = posState.getQuantitySetValue() - posState.getShowQuantitySetItem().quantityValue;
                    let removing = diff < 0;


                    posState.setShowQuantitySetDialog(undefined)
                    posState.updateCartItem(posState.getShowQuantitySetItem().itemId, Math.abs(diff), removing, undefined, undefined, undefined, {
                        recipeOptions: posState.getShowQuantitySetItem().recipeOptions,
                        batchNo: posState.getShowQuantitySetItem().invBatchNo
                    });
                }}>
                Set
            </Button>
        </ModalFooter>
    </ComposedModal>



    {/* Ending pos */}
    <ComposedModal key="apply-coupon-dialog" size="sm" open={posState.isShowCouponSelectDialog()} onClose={() => posState.setShowCouponSelectDialog(false)}>
        <ModalHeader label="POS" title="Apply Coupon" />
        <ModalBody>
            <TextInput
                invalidText="Invalid number"
                data-modal-primary-focus
                labelText="Coupon Code"
                value={posState.getCouponSelectCodeValue()}
                onChange={e => posState.setCouponSelectCodeValue(e.target.value)}
            />
        </ModalBody>
        <ModalFooter
        >
            <Button kind="secondary" onClick={() => posState.setShowCouponSelectDialog(false)}>
                Cancel
            </Button>
            <Button
                disabled={!Util.isStringExists(posState.getCouponSelectCodeValue()) || posState.isLoadingAddingCoupon()}
                onClick={() => posState.setApplyCoupon(posState.getCouponSelectCodeValue(), true)}>
                {posState.isLoadingAddingCoupon() ? "Loading..." : "Apply"}
            </Button>
        </ModalFooter>
    </ComposedModal>


    <PosFreeFormDialog posState={posState} />


    <SetCustomPriceDialog
        state={posState}
        cartItem={posState.getSetCustomPriceCartItem()}
        open={posState.isShowSetCartItemPriceDialog()} onClose={() => posState.closeSetCartItemPriceDialog()} />

    {posState.getState().receiptToPrint && <FinalReceiptDialog visible state={posState} receipt={posState.getState().receiptToPrint} onClose={() => posState.setState({ receiptToPrint: undefined })} />}

    {posState.isShowDeliveryCheckoutDialog() && <CreateDeliveryOrderDialog state={posState} onClose={() => posState.setShowDeliveryCheckoutDialog(false)} />}
</>)